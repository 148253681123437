import React from "react";
import { useDataFetching } from "../tools/DataFetching";
import { useAuthUser, useAuthHeader } from "react-auth-kit";

function MyOrders() {
  const auth = useAuthUser();
  const autha = useAuthHeader();

  const userToken = autha().slice(6);
  const { data, loading, error } = useDataFetching("orders", userToken);
  //add (!) before the loading
  let ordersData = [
    [
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/blogimgupload-3998a.appspot.com/o/Glee%2F3.png?alt=media&token=8beb379f-fadb-448e-b417-a9c13cd74adc3998a.appspot.com/o/Glee%2FIMG_5824.JPG?alt=media&token=9360fb83-f182-4dde-84d3-885acf0f622c",
        title: "ARGANILLA ARGAN OIL NOURISHING SHAMPOO",
        quantity: "12",
        date: "2024-04-27T22:26:52.725536Z",
        price: "500",
        total: "11000",
        orderStatus: "processing",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/blogimgupload-3998a.appspot.com/o/Glee%2F4.png?alt=media&token=1eda749b-257f-421b-ba39-cfd865c42a8a",
        title: "ARGANILLA ARGAN OIL CONDITIONER",
        quantity: "10",
        date: "2024-04-27T22:26:52.725536Z",
        price: "500",
        total: "11000",
        orderStatus: "processing",
      },
    ],
    [
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/blogimgupload-3998a.appspot.com/o/Glee%2Fbundles-logo%2F1.png?alt=media&token=a7bb144b-3373-4e47-a0cc-cc999b727fd2",
        title: "Shampoo  & Conditioner",
        quantity: "2",
        date: "2024-04-27T22:42:52.765822Z",
        price: "950",
        total: "4900",
        orderStatus: "processing",
      },
      {
        image:
          "https://firebasestorage.googleapis.com/v0/b/blogimgupload-3998a.appspot.com/o/Glee%2Fbundles-logo%2F3.png?alt=media&token=c41ea716-749b-4f1b-b591-62e381dbeaad",
        title: "Conditioner & Serum",
        quantity: "3",
        date: "2024-04-27T22:42:52.765822Z",
        price: "1000",
        total: "4900",
        orderStatus: "processing",
      },
    ],
  ];
  // let ordersData = {};
  function formatDate(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month because January is 0
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }
  console.log(loading);
  console.log(data);
  if (!loading && data) {
    ordersData = data.data;
    console.log(ordersData);
  }

  return (
    <div>
      {!loading && !ordersData.length && (
        // {!ordersData.length && (
        <img
          className="no-orders-img"
          src="https://firebasestorage.googleapis.com/v0/b/blogimgupload-3998a.appspot.com/o/no-order-a-flat-rounded-icon-is-up-for-premium-use-vector.jpg?alt=media&token=2e92e1e7-f3bf-4ff9-9a3d-ae8249c25526"
          alt="no orders photo"
        ></img>
      )}
      {ordersData.length > 0 && (
        <div>
          <div className="my-orders-container">
            {ordersData.map((arrayOfProd, index) => (
              <div className="one-order-container" key={index}>
                <div className="order-date">
                  <h2>{formatDate(arrayOfProd[0].date)}</h2>
                </div>
                {arrayOfProd.map((product, subIndex) => (
                  <div className="order-products" key={subIndex}>
                    <div className="order-details">
                      <img
                        src={product.image}
                        className="orders-images"
                        alt={product.title}
                      />
                      <div className="orders-title">
                        <h2>{product.title}</h2>
                        <h2>{product.price} L.E.</h2>
                      </div>
                      <div className="order-status">{product.orderStatus}</div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default MyOrders;
